import firebase from 'firebase'
import 'firebase/firebase-auth.js';
import 'firebase/firebase-database.js';

import { firebaseConfig } from 'constants/defaultValues.js';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.database();

export { auth, database };
