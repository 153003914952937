import { combineReducers } from 'redux';
import settings from './settings/reducer.js';
import menu from './menu/reducer.js';
import authUser from './auth/reducer.js';
import todoApp from './todo/reducer.js';
import chatApp from './chat/reducer.js';
import surveyListApp from './surveyList/reducer.js';
import surveyDetailApp from './surveyDetail/reducer.js';
import surveyResultsApp from './surveyResults/reducer.js';
import SurveyResultsJRApp from './surveyResultsJR/reducer.js';
import SurveyResultsSPApp from './surveyResultsSP/reducer.js';
import SurveyResultsISRApp from './surveyResultsISR/reducer.js';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  surveyResultsApp,
  SurveyResultsJRApp,
  SurveyResultsSPApp,
  SurveyResultsISRApp,

});

export default reducers;
