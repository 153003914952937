import {
  SURVEY_GET_RESULTSISR,
  SURVEY_GET_RESULTSISR_SUCCESS,
  SURVEY_GET_RESULTSISR_ERROR,
} from '../contants.js';

const INIT_STATE = {
  survey: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SURVEY_GET_RESULTSISR:
      return { ...state, loading: false };

    case SURVEY_GET_RESULTSISR_SUCCESS:
      return { ...state, loading: true, survey: action.payload };

    case SURVEY_GET_RESULTSISR_ERROR:
      return { ...state, loading: true, error: action.payload };
  
    default:
      return { ...state };
  }
};
