import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import surveyResultsData from 'data/survey.detail.json';
import { SURVEY_GET_RESULTS } from '../contants.js';

import {
  getSurveyResultsSuccess,
  getSurveyResultsError,
} from './actions.js';

const getSurveyResultsRequest = async () => {
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success(surveyResultsData.data);
    }, 1000);
  })
    .then((response) => response)
    .catch((error) => error);
};


function* getSurveyResultsItems() {
  try {
    const response = yield call(getSurveyResultsRequest);
    yield put(getSurveyResultsSuccess(response));
  } catch (error) {
    yield put(getSurveyResultsError(error));
  }
}

export function* watchGetResults() {
  yield takeEvery(SURVEY_GET_RESULTS, getSurveyResultsItems);
}


export default function* rootSaga() {
  yield all([fork(watchGetResults)]);
}
