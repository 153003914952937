import { all } from 'redux-saga/effects';
import authSagas from './auth/saga.js';
import todoSagas from './todo/saga.js'
import chatSagas from './chat/saga.js'
import surveyListSagas from './surveyList/saga.js'
import surveyDetailSagas from './surveyDetail/saga.js'
import surveyResultsSagas from './surveyResults/saga.js'

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    surveyResultsSagas(),
  ]);
}
