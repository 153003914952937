import {
  SURVEY_GET_RESULTS,
  SURVEY_GET_RESULTS_SUCCESS,
  SURVEY_GET_RESULTS_ERROR,
} from '../contants.js';

export const getSurveyResults = () => ({
  type: SURVEY_GET_RESULTS,
});

export const getSurveyResultsSuccess = (items) => ({
  type: SURVEY_GET_RESULTS_SUCCESS,
  payload: items,
});

export const getSurveyResultsError = (error) => ({
  type: SURVEY_GET_RESULTS_ERROR,
  payload: error,
});



